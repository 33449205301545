import { Component, EnvironmentInjector, OnInit, Optional, TemplateRef } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { hasPermissionByKey } from '@app/_helpers/permission';
import { useUserRulebreaksParser } from '@app/_helpers/rulebreaks/utils';
import { ParsedComegoTime } from '@app/_helpers/types';
import {
  createDateRangeArray,
  createRxValue,
  createScheduleDayParser,
  createScheduleDayParserFromUser,
} from '@app/_helpers/utils';
import { CheckWorkingHourErrorsOptions, checkWorkingHourErrors } from '@app/_helpers/workingHourUtils';
import { COMEGO_ICON_MAP } from '@app/components/come-and-go-create-dialog/come-and-go-utils';
import {
  ComeAndGoUpdateDialogComponent,
  ComeGoUpdateDialogData,
} from '@app/components/come-and-go-update-dialog/come-and-go-update-dialog.component';
import { HomeService } from '@app/pages/home-page/home.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isBefore, isSameDay, isValid, isWithinInterval, startOfMinute, startOfToday } from 'date-fns/esm';
import { combineLatest, filter, map, startWith, timer, withLatestFrom } from 'rxjs';
import { firstBy } from 'thenby';
import { ComegoQuery, ComegoService, ComegoTime, ComegoTimeType, Logger, Time, UserSettingsQuery } from 'timeghost-api';
import { RecordToolbarService } from '../record-toolbar/record-toolbar.service';
export const disabledFlags: ComegoTimeType[] = ['pause'];
const log = new Logger('TimeComegoViewComponent');
type TimesListView = Array<{
  date: Date;
  id: string;
  times: ParsedComegoTime[];
  schedule: ReturnType<ReturnType<typeof createScheduleDayParser>>;
  editable: boolean;
  deleteable: boolean;
  meta: Record<string, any>;
  sum: { [K in ComegoTimeType]: number };
}>;
@UntilDestroy()
@Component({
  selector: 'tg-time-comego-view',
  templateUrl: './time-comego-view.component.html',
  styleUrls: ['./time-comego-view.component.scss'],
})
export class TimeComegoViewComponent implements OnInit {
  readonly iconMap = COMEGO_ICON_MAP;
  constructor(
    private comegoQuery: ComegoQuery,
    private comego: ComegoService,
    @Optional() private homeService: HomeService,
    private userSettingsQuery: UserSettingsQuery,
    private recordService: RecordToolbarService,
    private dialog: MatDialog,
    private envInjector: EnvironmentInjector,
  ) {}
  readonly isAMPM$ = this.userSettingsQuery.select().pipe(map((x) => !x.settings?.timeFormat24h));
  readonly range$ = createRxValue<DateRange<Date>>(null);
  private viewRange$ = (this.homeService?.range || this.range$).asObservable(true);
  readonly times$ = combineLatest([
    this.comegoQuery.selectAll().pipe(
      withLatestFrom(this.userSettingsQuery.select((x) => x.id)),
      map(([x, userId]) => x.filter((t) => t.user.id === userId).sort(firstBy((f) => f.start, 'desc'))),
    ),
    this.viewRange$,
    timer(0, 5000).pipe(
      untilDestroyed(this),
      withLatestFrom(
        this.comegoQuery.selectAll({ filterBy: (x) => !x.end, limitTo: 1 }),
        this.userSettingsQuery.select(),
      ),
      filter(([, x, user]) => !!x.find((d) => !d.end && d.user?.id === user.id)),
      startWith(0),
    ), // update combine if a recording is running
  ]).pipe(
    untilDestroyed(this),
    map(([times, range]) => {
      let newTimes = times.map((t) => {
        const end = new Date(t.end || Date.now());
        return { ...t, start: new Date(t.start), end: isValid(end) ? end : null, isRecording: !t.end };
      });

      if (range) {
        newTimes = newTimes.filter((t) => isWithinInterval(t.start, range));
      }
      return newTimes.reduce(
        (acc, r) => {
          acc.comego?.push(r as any);
          return acc;
        },
        { comego: [], length: newTimes.length } as {
          comego: ParsedComegoTime[];
          length: number;
        },
      );
    }),
  );
  private checkWorkingHourErrors(times: ComegoTime[], date?: Date, options?: Partial<CheckWorkingHourErrorsOptions>) {
    let errors: ReturnType<typeof checkWorkingHourErrors> = checkWorkingHourErrors.bind(this.envInjector)(
      times,
      this.userSettingsQuery.getValue(),
      date,
      true,
      options,
    );
    if (!errors?.length) return null;
    return errors;
  }
  readonly parseRulebreaks = useUserRulebreaksParser();
  readonly times$listView = combineLatest([this.times$, this.viewRange$, this.userSettingsQuery.select()]).pipe(
    map(([times, range, userSettings]) => {
      if (!times.comego?.length) return null;
      const user = userSettings.workspace.users.find((x) => x.id === userSettings.id);
      const timesDefault = createDateRangeArray(range.start, range.end)[0].map(
        ([date]): TimesListView[0] & { isPending: boolean } => ({
          date,
          times: [],
          id: date.toISOString(),
          schedule: null,
          editable: hasPermissionByKey(userSettings, 'groupsCanComegoUpdateTimes' as any),
          deleteable: hasPermissionByKey(userSettings, 'groupsCanComegoUpdateTimes' as any),
          isPending: !isBefore(date, startOfToday()),
          sum: {
            pause: 0,
            work: 0,
            absence: 0,
          },
          meta: {},
        }),
      ) as TimesListView;
      let newTimes: TimesListView = times.comego.reduce(
        (acc, { isRecording, ...r }: ParsedComegoTime & { isRecording: boolean }, i, arr) => {
          if (r.user.id !== userSettings.id) return acc;
          const timeParent = acc.find((x) => isSameDay(x.date, r.start));
          const isValidEnd = r.end && isValid(r.end);
          const wstart = startOfMinute(r.start),
            wend = startOfMinute(r.end);
          const timeDiff = ((isValidEnd && (wend.getTime() - wstart.getTime()).clampThis(0.0)) || 0) / 1000;
          if (timeParent) {
            if (timeDiff) timeParent.sum[r.type] += timeDiff;
            timeParent.times.push({
              ...r,
              end: isValidEnd ? r.end : null,
              timeDiff,
              meta: {
                paused: r.type === 'pause',
                resume: r.type === 'work',
                start: i === 0,
                stopped: i === arr.length - 1,
                recording: isRecording,
                single: false,
                type: r.type,
              },
            } as any);
          }
          return acc;
        },
        timesDefault,
      );
      if (!newTimes.length) return null;
      newTimes = newTimes.filter((x) => x.times?.length).sort(firstBy((x: any) => x.date, 'desc'));
      const now = new Date();
      newTimes = newTimes.map((t) => {
        t.schedule = createScheduleDayParserFromUser(userSettings, t.date)?.(
          t.times.filter((x) => !disabledFlags.includes(x.type) && x.end) as any as Time[],
          t.date,
        );
        if (t.schedule) {
          const isPastSchedule = startOfToday().getTime() > t.date.getTime(),
            pastFailure = isPastSchedule && t.schedule.usage < t.schedule.cap;
          t.schedule = {
            ...t.schedule,
            isPastSchedule,
            percent: t.schedule.usage / t.schedule.cap,
            graphState: pastFailure ? 'warn' : t.schedule.usage < t.schedule.cap ? 'accent' : 'success',
            pending: t.schedule.cap - t.schedule.usage,
          } as any;
        }
        let lastErrorIdx = 0;
        const isMonday = t.date.getUTCDay() === 0;
        const { times: newTimes, issues: workinghoursErrors } = this.parseRulebreaks(
          user,
          userSettings,
          t.times as any,
        )[0];
        t.times = newTimes.sort(
          firstBy((x: ParsedComegoTime) => x.start, 'desc').thenBy((x) => (!x.end ? now : x.end), 'desc'),
        ) as any;
        if (workinghoursErrors?.length) t.meta.workinghoursErrors = workinghoursErrors;
        return t;
      });
      log.debug('ccview:', newTimes);
      return newTimes;
    }),
  );
  ngOnInit(): void {}
  trackId(i: number, { id }: { id: string }) {
    return id;
  }
  editItem(view: TimesListView[0], refItem: TimesListView[0]['times'][0]) {
    return this.dialog.open(ComeAndGoUpdateDialogComponent, {
      data: {
        entity: refItem as any,
      } as ComeGoUpdateDialogData,
    });
  }

  async deleteItem(
    view: TimesListView[0],
    refItem: TimesListView[0]['times'][0],
    confirmDeleteTemplate?: TemplateRef<any>,
  ) {
    if (confirmDeleteTemplate) {
      const allowDelete = await this.dialog
        .open(confirmDeleteTemplate, {
          data: {
            entity: refItem,
          },
        })
        .afterClosed()
        .toPromise()
        .then((x) => x === true)
        .catch(() => false);
      if (!allowDelete) {
        return;
      }
    }
    await this.comego.delete(refItem as any);
  }
}
